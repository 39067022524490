
import { useStore } from 'vuex'
import { ref, defineComponent, computed } from 'vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import ZonesTable from './ZonesTable.vue'
// import * as actions from '@/store/actions.type'
import ModalSlot from '@/components/General/ModalSlot.vue'
import VendorZonesForm from './new/index.vue'
import RatesForm from '../rates/new/index.vue'
import { useSearchMapping } from '@/hooks/useSearchMappingHook'
import { IVendorZoneSearchMapping } from '@/types/interfaces/IVendorZoneSearchMapping'

export default defineComponent({
  name: 'Zones',
  components: { SyncOutlined, ModalSlot, ZonesTable, VendorZonesForm, RatesForm },
  setup() {
    const store = useStore()
    const isZonesLoading = computed(() => store.state.zones.zonesLoading)
    const zoneEditData = ref<IVendorZoneSearchMapping | null>(null)
    const zonesExists = computed(() => store.getters['zones/zonesExists'])
    const reloadIconType = ref(false)
    const isZoneModalVisible = ref(false)
    const isRateModalVisible = ref(false)
    const { zonesSearchList } = useSearchMapping()

    const selectedServices = ref<string[]>([])

    const { vendorsSearchList, vendorServicesSearchList } = useSearchMapping()

    const vendorServiceMapping = computed(() => {
      return vendorsSearchList.value.map((v) => {
        return {
          ...v,
          services: [...vendorServicesSearchList.value.filter((s) => s.vendorId === v.id)],
        }
      })
    })

    const handleDataReload = async () => {
      reloadIconType.value = true
      // await store.dispatch(`zones/${actions.ZonesAction.FETCH_ZONES_ON_LOAD}`)
      reloadIconType.value = false
    }

    const handleChange = (val: any) => {
      console.log(val)
      console.log(selectedServices.value)
    }

    const handleAddNewZone = () => {
      zoneEditData.value = null
      isZoneModalVisible.value = true
    }

    const handleEditZone = (zoneId: string | null) => {
      if (zoneId) {
        isZoneModalVisible.value = true
        zoneEditData.value = zonesSearchList.value.find((x) => x.id === zoneId) || null
      }
    }

    return {
      isZonesLoading,
      zonesExists,
      reloadIconType,
      handleDataReload,
      isZoneModalVisible,
      selectedServices,
      vendorServiceMapping,
      handleChange,
      isRateModalVisible,
      zoneEditData,
      handleEditZone,
      handleAddNewZone,
    }
  },
})
