
import { VendorZoneFormData } from '@/types/appcontracts/VendorZoneFormData'
import { computed, defineComponent, onMounted, PropType, reactive, ref, toRaw, watch } from 'vue'
import { useStore } from 'vuex'
// import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
// import { DocumentActions } from '@/types/enums/DocumentActions'
import * as actions from '../../../../store/actions.type'
import { VendorZoneTypes } from '@/types/enums/VendorZoneTypes'
import { IVendorServiceSearchMapping } from '@/types/interfaces/IVendorServiceSearchMapping'
import { IVendorSearchMapping } from '@/types/interfaces/IVendorSearchMapping'
import { IVendorZoneSearchMapping } from '@/types/interfaces/IVendorZoneSearchMapping'
import { RuleObject } from 'ant-design-vue/lib/form/interface'
import { isStringEqual } from '@/services/helpers'
import { ICountryStateResponse } from '@/types/interfaces/ICountryStateResponse'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { VendorServicesDocument } from '@/types/firebaseCollectionContracts/VendorServicesDocument'
import { IAppDocument } from '@/types/interfaces/IAppDocument'

export default defineComponent({
  name: 'VendorZonesForm',
  props: {
    editData: {
      type: Object as PropType<IVendorZoneSearchMapping | null>,
      default: () => null,
    },
  },
  setup(props) {
    const store = useStore()
    const isEdit = computed(() => props.editData !== null)
    const isFormLoading = ref<boolean>(false)
    const appCountries = computed(() => store.state.settings.countries as ICountryStateResponse[])
    const appStates = computed(() => store.state.settings.indianStates as ICountryStateResponse[])
    const zonesSearchList = computed(
      () => store.state.zones.zonesSearchList as IVendorZoneSearchMapping[],
    )

    const loading = ref(false)
    const zoneTypesObj = VendorZoneTypes
    const initialFormState: VendorZoneFormData = {
      zoneType: VendorZoneTypes.Pincode,
      name: null,
      vendorId: null,
      serviceId: null,
      areas: [],
    }
    const selectedStates = ref<Array<string>>([])
    const formState = reactive<VendorZoneFormData>({ ...initialFormState })

    const isZoneNameUnique = async (rule: RuleObject, value: string) => {
      return zonesSearchList.value.some(
        (x) =>
          x.serviceId === formState.serviceId &&
          isStringEqual(x.name, value, true, true) &&
          !isStringEqual(x.name, props.editData?.name || '', true, true),
      )
        ? Promise.reject('Zone already exists.')
        : Promise.resolve()
    }

    // TODO: Check this is not working with areas
    // const isAreasValid = async (rul: RuleObject, value: string) => {
    //   if (!value || value.length === 0) {
    //     return Promise.reject('Please enter data')
    //   } else {
    //     return Promise.resolve()
    //   }
    // }

    const vendorList = computed(
      () => store.state.vendors.vendorsSearchList as IVendorSearchMapping[],
    )
    const vendorServiceList = computed(
      () => store.state.vendorServices.vendorServicesSearchList as IVendorServiceSearchMapping[],
    )
    const filteredServiceList = computed(() =>
      vendorServiceList?.value?.filter((x) => x.vendorId === formState.vendorId),
    )

    // Reset areas whenever zoneType changes.
    const handleZoneTypeChange = () => {
      formState.areas = []
      selectedStates.value = []
    }

    const rules = {
      vendorId: [
        { required: true, message: 'Please select a Vendor', trigger: 'change', type: 'string' },
      ],
      serviceId: [
        { required: true, message: 'Please select a Service', trigger: 'blur', type: 'string' },
      ],
      zoneType: [{ required: true, message: 'Please select zone type', trigger: 'blur' }],
      name: [
        {
          required: true,
          type: 'string',
          message: 'Please add name of the zone',
          trigger: 'blur',
        },
        { validator: isZoneNameUnique, trigger: 'blur' },
      ],
      // TODO: Check areas validation is not working
      // { validator: isAreasValid, trigger: 'blur', type: 'array' },
      areas: [{ required: true, message: 'Please enter data', trigger: 'blur', type: 'array' }],
    }

    const handleFinish = async () => {
      loading.value = true
      const docOperation: DocumentOperation<VendorZoneFormData> = {
        action: isEdit.value === false ? DocumentActions.CREATE : DocumentActions.UPDATE,
        id: isEdit.value === false ? null : props.editData?.id || null,
        payload: toRaw(formState),
        audit: null, // Send Audit for Update ??
      }
      const isSuccess = await store.dispatch(
        `zones/${actions.ZonesAction.SET_OR_DELETE_ZONE}`,
        docOperation,
      )
      loading.value = false
      if (isSuccess) {
        Object.assign(formState, { ...initialFormState })
      }
    }

    // TODO: Remove if not needed.
    const handleFinishFailed = async (errors: any) => {
      console.log('ERR: ', errors)
    }

    const fetchServiceById = async () => {
      isFormLoading.value = true
      if (props.editData?.serviceId) {
        const resp: IAppDocument<
          VendorServicesDocument
        > | null = await store.dispatch(
          `vendorServices/${actions.VendorServicesAction.FETCH_SERVICE_BY_ID}`,
          { docId: props.editData?.serviceId },
        )

        if (resp) {
          formState.name = props.editData.name
          formState.zoneType = props.editData.zoneType
          formState.serviceId = props.editData.serviceId
          formState.vendorId = resp.doc.vendorId
          formState.areas =
            resp.doc.zoneList?.find((x) => x.zid === props.editData?.id)?.areas || []
        }
      }
      isFormLoading.value = false
    }

    onMounted(async () => {
      await fetchServiceById()
    })

    // Not sure why 1st param is a func
    watch(
      () => formState.vendorId,
      () => {
        if (!isEdit.value) {
          formState.serviceId = null
        }
      },
    )

    return {
      formState,
      rules,
      vendorList,
      zoneTypesObj,
      loading,
      handleFinish,
      handleFinishFailed,
      filteredServiceList,
      appCountries,
      appStates,
      selectedStates,
      handleZoneTypeChange,
      isEdit,
      isFormLoading,
    }
  },
})
