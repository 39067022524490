
import { computed, defineComponent, PropType, reactive, ref } from 'vue'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { VendorsDocument } from '@/types/firebaseCollectionContracts/VendorsDocument'
import { useSearchMapping } from '@/hooks/useSearchMappingHook'
import ButtonGroupActions from '@/components/General/ButtonGroupActions.vue'
import { ActionButtonList } from '@/types/enums/ActionButtonList'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { useStore } from 'vuex'
import * as actions from '@/store/actions.type'

const columns = [
  {
    title: 'Vendor',
    dataIndex: 'vendorName',
    key: 'vendorName',
  },
  {
    title: 'Service',
    dataIndex: 'serviceName',
    key: 'serviceName',
  },

  {
    title: 'Zone Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Zone Type',
    dataIndex: 'zoneType',
    key: 'zoneType',
    slots: { customRender: 'zoneType' },
  },
  {
    title: 'Actions',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
]

export default defineComponent({
  name: 'VendorsTable',
  components: { ButtonGroupActions },
  props: {
    filteredServices: {
      type: Object as PropType<string[]>,
      default: () => [],
    },
  },
  emits: ['editZone'],
  setup(props, { emit }) {
    const store = useStore()
    const tableLoading = ref(false)
    const alertMessage = ref('')
    const { zoneList } = useSearchMapping()
    const actionButtonList = [ActionButtonList.VIEW, ActionButtonList.EDIT, ActionButtonList.DELETE]

    const totalTableRecords = computed(() => {
      return zoneList?.value.length
    })
    const showTotal = (total: number, range: Array<any>) => {
      return `${range[0]}-${range[1]} of ${total} items`
    }
    const pagination = reactive({
      position: 'bottom',
      showSizeChanger: true,
      showQuickJumper: false,
      total: totalTableRecords,
      simple: false,
      pageSize: 10,
      showTotal: showTotal,
    })

    const filteredDataSource = computed(() => {
      if (props.filteredServices.length > 0) {
        return zoneList.value.filter((x) =>
          props.filteredServices.some((s) => s.includes(x.serviceId || 'N/A')),
        )
      }
      return zoneList.value
    })

    const handleViewClick = (record: IAppDocument<VendorsDocument>) => {
      console.log('TODO: View - ', record)
    }
    const handleDeleteClick = async (record: IAppDocument<VendorsDocument>) => {
      tableLoading.value = true
      const docOperation: DocumentOperation<null> = {
        action: DocumentActions.DELETE,
        id: record.id,
        payload: null,
        audit: null, // Send Audit for Update ??
      }
      await store.dispatch(`zones/${actions.ZonesAction.SET_OR_DELETE_ZONE}`, docOperation)
      tableLoading.value = false
    }

    const handleChange = async () => {
      tableLoading.value = true
      // const required: number = page.current * page.pageSize

      // if (required > zoneList.value.length) {
      //   const fetchSize = required - zoneList.value.length
      //   await store.dispatch(`zones/${actions.ZonesAction.FETCH_ZONES_FROM_SERVER}`, {
      //     fetchSize,
      //   })
      // }
      tableLoading.value = false
    }

    const handleEditClick = (record: any) => {
      // EMIT to parent to leverage modal.
      emit('editZone', record?.id)
    }

    return {
      columns,
      handleViewClick,
      pagination,
      handleChange,
      tableLoading,
      alertMessage,
      filteredDataSource,
      handleEditClick,
      // vendorServicesList,
      handleDeleteClick,
      actionButtonList,
    }
  },
})
