<template>
  <div>
    <div class="d-flex flex-wrap border-bottom mb-3">
      <div class="mt-4 mr-auto">
        <h4>Zones</h4>
      </div>

      <div>
        <div class="btn btn-success btn-with-addon text-nowrap my-3 mr-3" @click="handleAddNewZone">
          <span class="btn-addon">
            <i class="btn-addon-icon fe fe fe-plus"></i>
          </span>
          Zone
        </div>
      </div>
      <div>
        <div
          class="btn btn-success text-blue btn-with-addon text-nowrap my-3 mr-3"
          @click="isRateModalVisible = true"
        >
          <span class="btn-addon">
            <i class="btn-addon-icon fe fe fe-plus"></i>
          </span>
          Rate
        </div>
      </div>
      <div class="dropdown my-3 mr-3">
        <a-dropdown placement="bottomLeft" :trigger="['click']">
          <a class="btn btn-light text-blue dropdown-toggle" href="javascript: void(0);">Actions</a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <router-link
                  :to="{
                    name: 'importFile',
                    query: { entity: 'zones' },
                  }"
                >
                  Import
                </router-link>
                <!-- <a href="javascript:;">Import</a> -->
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;">Export</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <div class="d-flex flex-column justify-content-center">
        <a-tooltip title="Refresh">
          <button type="button" class="btn btn-light" @click="handleDataReload">
            <SyncOutlined :spin="reloadIconType" />
          </button>
        </a-tooltip>
      </div>
    </div>
    <div>
      <a-skeleton :loading="isZonesLoading" active>
        <div class="text-center my-5" v-if="!zonesExists">
          <h3>Create Zones and Rates here.</h3>
          <p class="text-muted">
            Create and manage your zones, all in one place.
          </p>
          <!-- <template> -->
          <div>
            <a-empty :description="false" />
          </div>
          <!-- </template> -->
          <div>
            <div class="btn btn-success my-2 text-uppercase" @click="isZoneModalVisible = true">
              Create New Zone
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-lg-6">
              <a-select
                mode="multiple"
                placeholder="Filter by Services"
                style="width: 100%"
                :token-separators="[',']"
                allow-clear
                v-model:value="selectedServices"
                @change="handleChange"
              >
                <a-select-opt-group
                  v-for="vendorService in vendorServiceMapping"
                  :key="vendorService.id"
                  :label="vendorService.displayName"
                >
                  <a-select-option
                    v-for="service in vendorService?.services"
                    :key="service.id"
                    :value="service.id"
                  >
                    {{ service.name }} ({{ service.alias }})
                  </a-select-option>
                </a-select-opt-group>
              </a-select>
            </div>
          </div>
          <div>
            <ZonesTable :filtered-services="selectedServices" @editZone="handleEditZone" />
          </div>
        </div>
      </a-skeleton>
    </div>

    <div>
      <ModalSlot
        v-model:modal-visible="isZoneModalVisible"
        :modal-title="zoneEditData === null ? 'Add Zone' : 'Update Zone'"
        modal-width="800"
      >
        <VendorZonesForm :edit-data="zoneEditData" />
      </ModalSlot>
    </div>

    <div>
      <ModalSlot
        v-model:modal-visible="isRateModalVisible"
        modal-title="Add Rate"
        modal-width="800"
      >
        <div style="height: 430px; width: 100%; overflow-y: auto; overflow-x: hidden">
          <RatesForm />
        </div>
      </ModalSlot>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { ref, defineComponent, computed } from 'vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import ZonesTable from './ZonesTable.vue'
// import * as actions from '@/store/actions.type'
import ModalSlot from '@/components/General/ModalSlot.vue'
import VendorZonesForm from './new/index.vue'
import RatesForm from '../rates/new/index.vue'
import { useSearchMapping } from '@/hooks/useSearchMappingHook'
import { IVendorZoneSearchMapping } from '@/types/interfaces/IVendorZoneSearchMapping'

export default defineComponent({
  name: 'Zones',
  components: { SyncOutlined, ModalSlot, ZonesTable, VendorZonesForm, RatesForm },
  setup() {
    const store = useStore()
    const isZonesLoading = computed(() => store.state.zones.zonesLoading)
    const zoneEditData = ref<IVendorZoneSearchMapping | null>(null)
    const zonesExists = computed(() => store.getters['zones/zonesExists'])
    const reloadIconType = ref(false)
    const isZoneModalVisible = ref(false)
    const isRateModalVisible = ref(false)
    const { zonesSearchList } = useSearchMapping()

    const selectedServices = ref<string[]>([])

    const { vendorsSearchList, vendorServicesSearchList } = useSearchMapping()

    const vendorServiceMapping = computed(() => {
      return vendorsSearchList.value.map((v) => {
        return {
          ...v,
          services: [...vendorServicesSearchList.value.filter((s) => s.vendorId === v.id)],
        }
      })
    })

    const handleDataReload = async () => {
      reloadIconType.value = true
      // await store.dispatch(`zones/${actions.ZonesAction.FETCH_ZONES_ON_LOAD}`)
      reloadIconType.value = false
    }

    const handleChange = (val: any) => {
      console.log(val)
      console.log(selectedServices.value)
    }

    const handleAddNewZone = () => {
      zoneEditData.value = null
      isZoneModalVisible.value = true
    }

    const handleEditZone = (zoneId: string | null) => {
      if (zoneId) {
        isZoneModalVisible.value = true
        zoneEditData.value = zonesSearchList.value.find((x) => x.id === zoneId) || null
      }
    }

    return {
      isZonesLoading,
      zonesExists,
      reloadIconType,
      handleDataReload,
      isZoneModalVisible,
      selectedServices,
      vendorServiceMapping,
      handleChange,
      isRateModalVisible,
      zoneEditData,
      handleEditZone,
      handleAddNewZone,
    }
  },
})
</script>
